import React, { useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import moment from "moment";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import tw from "tailwind-styled-components";
import { RotatingLines } from "react-loader-spinner";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";
import autoAnimate from "@formkit/auto-animate";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaymentDetails } from "@containers";
import { PrimaryText, TostMessage, TermAndConditionModal } from "@components";
import { CheckLanguage, set } from "@utils";
import {
  payLoader,
  getCancelPolicy,
  getListingBookings,
  getSelectedPlayerData,
} from "@store";
import BookingDetails from "./bookingDetails";
import Config from "../../../config";
import "../../containerStyle.css";

const stripePromise = loadStripe(Config.STRIPE_PUBLISH_KEY);

const paymentOptions = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  appearance: {
    rules: {
      ".Label": {
        color: "#ffffffb3",
      },
    },
  },
};

function CheckOutForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const cancelPolicyData = useSelector(
    (state) => state.footers.cancelpolicyData
  );
  const userStatus = useSelector((state) => state.users.status);
  const paymentLoader = useSelector((state) => state.payment.payLoader);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilitiesData = clubData?.data?.attributes?.facility?.data;
  const apiTimer = useSelector((state) => state.setting.time);
  const cartData = useSelector((state) => state.bookings?.addToCartData);
  let addToCartData = cartData?.sort(function (left, right) {
    return moment
      .utc(left?.teeTime?.teeTimeData?.time)
      .diff(moment.utc(right?.teeTime?.teeTimeData?.time));
  });
  const playerTypeId = apiTimer?.data?.data?.attributes;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: "",
      lastName: "",
      email: "",
      telePhone: "",
      town: "",
      street: "",
      zipCode: "",
      companyName: "",
      specialRequests: "",
      title: "",
      checkBox: false,
      bookingList: [],
      userLoginError: false,
      payClick: false,
      multipleBookingPayment: [],
      cancelPolicyModal: false,
      termConditionModal: false,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  const parentRef = useRef();
  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);
  const showMoreCancelPolicy = () =>
    setState({ cancelPolicyModal: !state.cancelPolicyModal });

  const theme = createTheme({
    palette: {
      success: {
        main: "#ffffff",
      },
    },
  });

  useEffect(() => {
    fillUserDetail();
  }, []);

  const fillUserDetail = () => {
    const localUsers = localStorage.getItem("localUserData_teeTime");
    if (localUsers !== null) {
      let data = JSON.parse(localUsers);
      setState({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        telePhone: data?.telePhone,
        street: data?.street,
        companyName: data?.companyName,
        town: data?.town,
        zipCode: data?.zipCode,
        specialRequests: data?.specialRequests,
      });
    }
  };

  useEffect(() => {
    let dateLanguage = CheckLanguage() || "en";
    dispatch(getCancelPolicy(`cancellation-policy?locale=${dateLanguage}`));
  }, [dispatch, facilitiesData]);

  useEffect(() => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    if (localdata?.length && !addToCartData?.length) {
      dispatch(
        getSelectedPlayerData({ localdata: localdata, localSave: true })
      );
    }
  }, [dispatch, addToCartData]);

  useEffect(() => {
    const users = localStorage.getItem("UserData");
    if (users !== null) {
      const user_Data = JSON.parse(users);
      setState({
        firstName: user_Data?.user?.firstName,
        lastName: user_Data?.user?.lastName,
        email: user_Data?.user?.email,
        telePhone: user_Data?.user?.phone,
        street: user_Data?.user?.street,
        companyName: user_Data?.user?.companyName,
        town: user_Data?.user?.city,
        zipCode: user_Data?.user?.zipCode,
      });
    }
  }, [userStatus]);

  useEffect(() => {
    let list = [];
    let multipleBookPayment = [];

    if (playerTypeId && addToCartData) {
      // addToCartData?.map((item) => {
      //   list = [
      //     ...list,
      //     {
      //       teeId: item?.teeTime?.teeTimeData?.id,
      //       course: item?.teeTime?.teeTimeData?.course,
      //       players: item?.price?.players,
      //       teetime: moment(item?.teeTime?.teeTimeData?.time).format(
      //         "YYYY-MM-DD HH:mm:ss"
      //       ),
      //       holes: item?.teeTime?.teeTimeData?.holes,
      //       netRate: item?.price?.netRate?.amount,
      //       contactFirstName: state.firstName,
      //       contactLastName: state.lastName,
      //       playerType: playerTypeId?.casualPlayerTypeId || "",
      //     },
      //   ];
      // });
      addToCartData?.forEach((item) => {
        list = [
          ...list,
          {
            teeId: item?.teeTime?.teeTimeData?.id,
            course: item?.teeTime?.teeTimeData?.course,
            players: item?.price?.players,
            teetime: moment(item?.teeTime?.teeTimeData?.time).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            holes: item?.teeTime?.teeTimeData?.holes,
            netRate: item?.price?.netRate?.amount,
            contactFirstName: state.firstName,
            contactLastName: state.lastName,
            playerType: playerTypeId?.casualPlayerTypeId || "",
          },
        ];
      });
    }
    // addToCartData?.map((item, index) => {
    //   multipleBookPayment = [
    //     ...multipleBookPayment,
    //     {
    //       teeId: item?.teeTime?.teeTimeData?.id,
    //       course: item?.teeTime?.teeTimeData?.course,
    //       players: item?.price?.players,
    //       teeTime: moment(item?.teeTime?.teeTimeData?.time).format(
    //         "YYYY-MM-DDTHH:mm"
    //       ),
    //       holes: item?.teeTime?.teeTimeData?.holes,
    //       amount: item?.price?.publicRate?.amount,
    //     },
    //   ];
    // });
    addToCartData?.forEach((item, index) => {
      multipleBookPayment = [
        ...multipleBookPayment,
        {
          teeId: item?.teeTime?.teeTimeData?.id,
          course: item?.teeTime?.teeTimeData?.course,
          players: item?.price?.players,
          teeTime: moment(item?.teeTime?.teeTimeData?.time).format(
            "YYYY-MM-DDTHH:mm"
          ),
          holes: item?.teeTime?.teeTimeData?.holes,
          amount: item?.price?.publicRate?.amount,
        },
      ];
    });

    setState({
      bookingList: list,
      multipleBookingPayment: multipleBookPayment,
    });
  }, [addToCartData, state.firstName, state.lastName, playerTypeId]);

  const closeTermConditionModal = () => {
    setState({ termConditionModal: false });
  };

  const cancle = () => {
    navigate("/");
  };

  const checkPastTeeTime = () => {
    let currentTimeFilter = _.filter(addToCartData, function (e) {
      return moment().isSameOrAfter(e?.teeTime?.teeTimeData?.time);
    });
    if (currentTimeFilter?.length) {
      return true;
    } else {
      return false;
    }
  };

  const submitOk = async (e) => {
    e.preventDefault();
    if (state.checkBox) {
      dispatch(payLoader(true));
      let length = addToCartData?.length;
      let formDate = moment(addToCartData[0]?.teeTime?.teeTimeData?.time)
        .subtract(1, "day")
        .format("DD-MM-YYYY");
      let toDate = moment(addToCartData[length - 1]?.teeTime?.teeTimeData?.time)
        .add(1, "day")
        .format("DD-MM-YYYY");

      let bookingsApiData = {
        fromDate: formDate,
        toDate: toDate,
      };
      dispatch(getListingBookings(bookingsApiData)).then(async (res) => {
        if (
          (await res?.payload?.data?.data?.data) &&
          (await res?.payload?.status) === 200
        ) {
          let listingData = await res?.payload?.data?.data?.data;

          let result = [];
          if (addToCartData?.length) {
            // addToCartData?.map((r) => {
            //   result = result.concat(
            //     listingData?.filter((e) => {
            //       return (
            //         e?.teetime === r?.teeTime?.teeTimeData?.time &&
            //         e?.course === r?.teeTime?.teeTimeData?.course &&
            //         (e?.status === "BOOKED" || e?.status === "CREATED")
            //       );
            //     })
            //   );
            //   let totalPayers = result.reduce(
            //     (total, currentValue) => total + currentValue.players,
            //     0
            //   );

            //   if (
            //     result?.length &&
            //     (totalPayers >= 4 || 4 - totalPayers < r?.price?.players)
            //   ) {
            //     const remove_bookedTees = addToCartData.filter(
            //       (item1) =>
            //         !result.some(
            //           (item2) =>
            //             item1?.teeTime?.teeTimeData?.time === item2?.teetime &&
            //             item1?.teeTime?.teeTimeData?.course === item2?.course
            //         )
            //     );
            //     if (remove_bookedTees.length) {
            //       dispatch(
            //         getSelectedPlayerData({
            //           localdata: remove_bookedTees,
            //           localSave: true,
            //         })
            //       );
            //     } else {
            //       dispatch(getSelectedPlayerData({ save: false }));
            //       localStorage.removeItem("AddToCartsData");
            //     }

            //     setState({
            //       alertMessage: true,
            //       alertMessageTxt:
            //         "Selected tee time is already booked please choose another for booking !",
            //     });
            //     dispatch(payLoader(false));
            //   } else {
            //     setState({
            //       alertMessage: false,
            //       alertMessageTxt: null,
            //     });
            //     bookTeeTime();
            //   }
            // });
            addToCartData?.forEach((r) => {
              result = result.concat(
                listingData?.filter((e) => {
                  return (
                    e?.teetime === r?.teeTime?.teeTimeData?.time &&
                    e?.course === r?.teeTime?.teeTimeData?.course &&
                    (e?.status === "BOOKED" || e?.status === "CREATED")
                  );
                })
              );
              let totalPayers = result.reduce(
                (total, currentValue) => total + currentValue.players,
                0
              );

              if (
                result?.length &&
                (totalPayers >= 4 || 4 - totalPayers < r?.price?.players)
              ) {
                const remove_bookedTees = addToCartData.filter(
                  (item1) =>
                    !result.some(
                      (item2) =>
                        item1?.teeTime?.teeTimeData?.time === item2?.teetime &&
                        item1?.teeTime?.teeTimeData?.course === item2?.course
                    )
                );
                if (remove_bookedTees.length) {
                  dispatch(
                    getSelectedPlayerData({
                      localdata: remove_bookedTees,
                      localSave: true,
                    })
                  );
                } else {
                  dispatch(getSelectedPlayerData({ save: false }));
                  localStorage.removeItem("AddToCartsData");
                }

                setState({
                  alertMessage: true,
                  alertMessageTxt:
                    "Selected tee time is already booked please choose another for booking !",
                });
                dispatch(payLoader(false));
              } else {
                setState({
                  alertMessage: false,
                  alertMessageTxt: null,
                });
                bookTeeTime();
              }
            });
          } else {
            dispatch(payLoader(false));
            navigate(-1);
          }
        } else {
          setState({
            alertMessage: true,
            alertMessageTxt:
              "Selected tee time is already booked please choose another for booking !",
          });
          dispatch(payLoader(false));
        }
      });
    }
  };

  const bookTeeTime = () => {
    if (checkPastTeeTime()) {
      setState({
        alertMessage: true,
        alertMessageTxt:
          "Please Remove Past Tee Time in your cart before checkout!",
      });
      dispatch(payLoader(false));
    } else {
      dispatch(payLoader(false));
      setState({ payClick: true, userLoginError: false });
    }
  };

  const CancellationPolicy = () => {
    return (
      <CancelPolicyView ref={parentRef}>
        <div
          onClick={showMoreCancelPolicy}
          className="select-none font-bold w-full flex justify-between items-center cursor-pointer"
        >
          <PrimaryText className="text-xl text-textColor2 font-semibold font-[Cambay]">
            {t("checkOut.completeBooking.cancelPolicy")}
          </PrimaryText>
          <div
            className={`w-fit h-fit ${
              state.cancelPolicyModal
                ? "duration-500 transform rotate-90"
                : "duration-300 transform rotate-0"
            }`}
          >
            <ArrowRightIcon fontSize="large" />
          </div>
        </div>
        {state.cancelPolicyModal && (
          <div className="break-words hyphens-auto text-textColor2 opacity-80 pt-4">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              children={cancelPolicyData.toString()}
            />
          </div>
        )}
      </CancelPolicyView>
    );
  };

  const saveDetails = () => {
    let localUserData = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      telePhone: state.telePhone,
      street: state.street,
      companyName: state.companyName,
      town: state.town,
      zipCode: state.zipCode,
      specialRequests: state.specialRequests,
    };
    set("localUserData_teeTime", JSON.stringify(localUserData));
  };

  const CustomInput = (props) => {
    return (
      <div className="w-full ">
        <label className="text-[15px] text-textColor font-[Cambay] mb-2 flex flex-row">
          {props.title}{" "}
          {props.optional ? (
            <span className="text-textColor opacity-80 text-sm font-[Cambay]">
              {"(optional)"}
            </span>
          ) : (
            ""
          )}
          {props.require ? (
            <Tooltip title={`Please fill in this field.`} arrow>
              <div className="cursor-pointer">
                <span className="text-[#ff6d6d] text-sm font-[Cambay]">
                  {"*"}
                </span>
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </label>
        <input
          readOnly={props.readOnly}
          placeholder={props.placeholder ? props.placeholder : props.title}
          className={txtInput}
          type={props.type ? props.type : "text"}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          required={props.require}
          onInvalid={props.onInvalid}
          onInput={(e) => e.target.setCustomValidity("")}
          autoComplete="off"
          role="presentation"
          disabled={props.disabled}
        />
      </div>
    );
  };

  const BookingForm = () => {
    return (
      <>
        <form onSubmit={submitOk}>
          <div className="mb-4">
            <PrimaryText className={inputTitle}>
              {t("checkOut.completeBooking.personalDetails")}
            </PrimaryText>
          </div>
          <InputView>
            {CustomInput({
              title: t("checkOut.completeBooking.fName"),
              value: state.firstName,
              onChange: (event) => {
                setState({ firstName: event.target.value });
                saveDetails();
              },
              maxLength: 30,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterFName")
                );
              },
              disabled: paymentLoader,
            })}
            {CustomInput({
              title: t("checkOut.completeBooking.lName"),
              value: state.lastName,
              onChange: (event) => {
                setState({ lastName: event.target.value });
                saveDetails();
              },
              maxLength: 30,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterLName")
                );
              },
              disabled: paymentLoader,
            })}
          </InputView>
          <InputView>
            {CustomInput({
              title: t("checkOut.completeBooking.email"),
              value: state.email,
              type: "email",
              onChange: (event) => {
                setState({ email: event.target.value });
                saveDetails();
              },
              maxLength: 40,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterEmail")
                );
              },
              disabled: paymentLoader,
            })}
            {CustomInput({
              title: t("checkOut.completeBooking.telePhone"),
              value: state.telePhone,
              onChange: (event) => {
                const regex = /^[0-9+-.\b]+$/;
                if (
                  event.target.value === "" ||
                  regex.test(event.target.value)
                ) {
                  setState({ telePhone: event.target.value });
                  saveDetails();
                }
              },
              maxLength: 15,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterPhoneNo")
                );
              },
              disabled: paymentLoader,
            })}
          </InputView>

          <div className="mb-4 mt-8">
            <PrimaryText className={inputTitle}>
              {t("checkOut.completeBooking.address")}
            </PrimaryText>
          </div>
          <InputView>
            {CustomInput({
              title: t("checkOut.completeBooking.street"),
              value: state.street,
              onChange: (event) => {
                setState({ street: event.target.value });
                saveDetails();
              },
              maxLength: 30,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterStreet")
                );
              },
              disabled: paymentLoader,
            })}
            {CustomInput({
              title: t("checkOut.completeBooking.companyName"),
              value: state.companyName,
              onChange: (event) => {
                setState({ companyName: event.target.value });
                saveDetails();
              },
              maxLength: 45,
              optional: true,
              disabled: paymentLoader,
            })}
          </InputView>
          <InputView>
            {CustomInput({
              title: t("checkOut.completeBooking.townCity"),
              value: state.town,
              onChange: (event) => {
                setState({ town: event.target.value });
                saveDetails();
              },
              maxLength: 30,
              require: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterTownName")
                );
              },
              disabled: paymentLoader,
            })}
            {CustomInput({
              title: t("checkOut.completeBooking.postZipCode"),
              value: state.zipCode,
              onChange: (event) => {
                setState({ zipCode: event.target.value });
                saveDetails();
              },
              maxLength: 25,
              optional: true,
              onInvalid: (e) => {
                e.target.setCustomValidity(
                  t("checkOut.completeBooking.enterZipCode")
                );
              },
              disabled: paymentLoader,
            })}
          </InputView>

          <div className="mb-4 mt-8">
            <PrimaryText className={inputTitle}>
              {t("checkOut.completeBooking.specialRequest")}
            </PrimaryText>
          </div>
          <PrimaryText className="text-textColor text-[16px] font-semibold">
            {t("checkOut.completeBooking.toArrange")}
          </PrimaryText>
          {CustomInput({
            title: t("checkOut.completeBooking.pleaseLetUs"),
            placeholder: t("checkOut.completeBooking.insertYour"),
            value: state.specialRequests,
            onChange: (event) => {
              setState({ specialRequests: event.target.value });
              saveDetails();
            },
            disabled: paymentLoader,
          })}

          <Elements stripe={stripePromise} options={paymentOptions}>
            <PaymentDetails
              onPayClick={() => setState({ payClick: false })}
              inputComplete={state.payClick}
              teeTime_Data={state}
              type={"individual"}
            />
          </Elements>

          <PayConditionView>
            <CheckboxView className="w-auto h-auto flex flex-row items-center">
              <ThemeProvider theme={theme}>
                <Checkbox
                  required
                  checked={state.checkBox}
                  color="success"
                  sx={{ color: "white" }}
                  onChange={(event) => {
                    setState({ checkBox: event.target.checked });
                  }}
                />
              </ThemeProvider>
              <PrimaryText className="text-textColor font-[Cambay] font-medium">
                {t("checkOut.completeBooking.iAgree")}
                <span
                  className="text-red255 underline font-[Cambay] font-medium ml-1 hover:cursor-pointer"
                  onClick={() => setState({ termConditionModal: true })}
                >
                  {" "}
                  {t("checkOut.completeBooking.termCondition")}
                </span>
              </PrimaryText>
            </CheckboxView>
          </PayConditionView>

          <Collapse in={state.userLoginError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ userLoginError: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              please login first then after payment —{" "}
              <strong>check it out!</strong>
            </Alert>
          </Collapse>

          <BtnView>
            <Cancle
              type="button"
              onClick={() => cancle()}
              disabled={paymentLoader}
            >
              <PrimaryText className={"text-white255 text-lg"}>
                {t("checkOut.booking.cancel")}
              </PrimaryText>
            </Cancle>
            <Submit
              type="submit"
              disabled={paymentLoader}
              className={`${
                paymentLoader
                  ? " opacity-75"
                  : "hover:from-primary hover:to-primary hover:cursor-pointer"
              }`}
            >
              {paymentLoader ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="3"
                  animationDuration="0.85"
                  width="30"
                  visible={true}
                />
              ) : (
                <PrimaryText className={"text-white255 text-lg"}>
                  {t("checkOut.booking.submit")}
                </PrimaryText>
              )}
            </Submit>
          </BtnView>
        </form>

        {CancellationPolicy()}
        <TermAndConditionModal
          openModal={state.termConditionModal}
          closeModal={closeTermConditionModal}
        />
      </>
    );
  };

  return (
    <Component>
      <CompleteBookingView>
        <div className="mb-[10px]">
          <PrimaryText className="text-textColor text-lg font-semibold uppercase">
            {t("checkOut.completeBooking.completeYourBooking")}
          </PrimaryText>
        </div>
        <div className={component}>{BookingForm()}</div>
      </CompleteBookingView>

      <BookingDetailView>
        <BookingDetails />
      </BookingDetailView>
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState({ alertMessage: false, alertMessageTxt: null })
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </Component>
  );
}

const Component = tw.div`
w-full 
h-auto 
flex 
xl1:flex-row  xl:flex-col  flex-col 
justify-between 
xl1:items-start  items-center
xl1:px-6  xl:px-[0px]  px-[0px]
`;
const CompleteBookingView = tw.div`
xl1:w-[63%] w-full 
h-auto 
`;

const component = `
w-full 
h-auto 
flex flex-col 
bg-boxBackground 
rounded-xl 
shadow-xl 
md3:p-4 p-2
`;
const BookingDetailView = tw.div`
xl1:w-[34%] w-full 
max-w-[500px]
h-auto
xl1:mt-0  mt-10
`;
const txtInput = `
w-full 
bg-textColor
text-textColor2 
border-[2px] 
rounded-lg
py-[11px]
mb-[12px]
px-4 
leading-tight 
focus:outline-none 
border-[#ffffff]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;
const inputTitle = `
text-textColor 
text-xl 
font-medium 
opacity-80
`;
const InputView = tw.div`
w-full 
h-auto 
flex  
md3:flex-row  flex-col 
items-start 
justify-between 
gap-x-4 
gap-y-2
`;
const PayConditionView = tw.div`
w-full
h-auto
flex
md3:flex-row  flex-col
md3:items-center items-start
justify-between
gap-y-4
mt-4
`;
const CheckboxView = tw.div`
w-auto 
h-auto 
flex 
flex-row 
items-center
mt-2
`;
const BtnView = tw.div`
flex 
flex-row 
justify-between 
items-cetner
w-full 
h-[46px] 
md3:mt-[30px]
mt-[30px]
`;
const Submit = tw.button`
w-[30%]
h-[44px]
rounded-[23px]
bg-gradient-to-r from-primary to-primary2
flex
justify-center
items-center
webkitHighlight
`;
const Cancle = tw.button`
w-[30%]
h-[44px]
rounded-[23px]
bg-gradient-to-r from-red255 to-[#ff3c4966]
hover:from-red255 hover:to-red255
flex
justify-center
items-center
webkitHighlight
`;
const CancelPolicyView = tw.div`
w-full 
h-auto 
bg-gray-200
rounded-lg
mt-6 
pl-4
pr-[6px]
py-[6px]
border-[1px] 
border-[#e6e6e6]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
whitespace-pre-line
webkitHighlight
`;

export default CheckOutForm;
